@font-face {
  font-family: 'InfraRegular';
  src: url('../fonts/InfraRegular/InfraRegular.eot');
  src: url('../fonts/InfraRegular/InfraRegular.eot') format('embedded-opentype'),
  url('../fonts/InfraRegular/InfraRegular.woff2') format('woff2'),
  url('../fonts/InfraRegular/InfraRegular.woff') format('woff'),
  url('../fonts/InfraRegular/InfraRegular.ttf') format('truetype'),
  url('../fonts/InfraRegular/InfraRegular.svg#InfraRegular') format('svg');
}
@font-face {
  font-family: 'InfraMedium';
  src: url('../fonts/InfraMedium/InfraMedium.eot');
  src: url('../fonts/InfraMedium/InfraMedium.eot') format('embedded-opentype'),
  url('../fonts/InfraMedium/InfraMedium.woff2') format('woff2'),
  url('../fonts/InfraMedium/InfraMedium.woff') format('woff'),
  url('../fonts/InfraMedium/InfraMedium.ttf') format('truetype'),
  url('../fonts/InfraMedium/InfraMedium.svg#InfraMedium') format('svg');
}
@font-face {
  font-family: 'UrbanistMedium';
  src: url('../fonts/UrbanistMedium/UrbanistMedium.eot');
  src: url('../fonts/UrbanistMedium/UrbanistMedium.eot') format('embedded-opentype'),
  url('../fonts/UrbanistMedium/UrbanistMedium.woff2') format('woff2'),
  url('../fonts/UrbanistMedium/UrbanistMedium.woff') format('woff'),
  url('../fonts/UrbanistMedium/UrbanistMedium.ttf') format('truetype'),
  url('../fonts/UrbanistMedium/UrbanistMedium.svg#UrbanistMedium') format('svg');
}
@font-face {
  font-family: 'UrbanistSemiBold';
  src: url('../fonts/UrbanistSemiBold/UrbanistSemiBold.eot');
  src: url('../fonts/UrbanistSemiBold/UrbanistSemiBold.eot') format('embedded-opentype'),
  url('../fonts/UrbanistSemiBold/UrbanistSemiBold.woff2') format('woff2'),
  url('../fonts/UrbanistSemiBold/UrbanistSemiBold.woff') format('woff'),
  url('../fonts/UrbanistSemiBold/UrbanistSemiBold.ttf') format('truetype'),
  url('../fonts/UrbanistSemiBold/UrbanistSemiBold.svg#UrbanistSemiBold') format('svg');
}
@font-face {
  font-family: 'UrbanistRegular';
  src: url('../fonts/UrbanistRegular/UrbanistRegular.eot');
  src: url('../fonts/UrbanistRegular/UrbanistRegular.eot') format('embedded-opentype'),
  url('../fonts/UrbanistRegular/UrbanistRegular.woff2') format('woff2'),
  url('../fonts/UrbanistRegular/UrbanistRegular.woff') format('woff'),
  url('../fonts/UrbanistRegular/UrbanistRegular.ttf') format('truetype'),
  url('../fonts/UrbanistRegular/UrbanistRegular.svg#UrbanistRegular') format('svg');
}
html {
  font-size: 10px;
}
.height-inherit{
  height: inherit;
}
.title-18{
  font-size: 18px;
  font-family: 'UrbanistMedium';
}
body {
  font-size: 1.4em;
  --ion-font-family: "Open Sans", 'InfraRegular', sans-serif;
}
.d-flex{
  display: flex!important;
}
.pl-0{
  padding-left: 0!important;
}
.pr-0{
  padding-right: 0!important;
}
.MuiButtonBase-root.MuiButton-root {
  text-transform: initial;
  line-height: 16px;
  min-height: 32px;
  &.MuiButton-sizeSmall {
    padding: 7px 22px;
  }
  &.MuiButton-sizeMedium {
    padding: 4px 22px;
    min-height: 42px;
  }
  &.MuiButton-sizeLarge {
    padding: 2px 22px;
    min-height: 42px;
  }
}

.MuiPopover-paper a {
  color: var(--ion-text-color);
}

.MuiTableCell-root div.mod {
  opacity: 0.75;
  font-size: 0.8em;
}

/*custom style*/
.white-color{
  color: #fff!important;
}

.logo-box{
  ion-img{
    max-width: 100px;
    margin: 0 auto;
  }
}
.footer-md{
  &::before{
    background-image: none;
  }
}
.full-width{
  width: 100%;
}
.ion-border{
  border: 1px solid #ccc;
}
.ion-border-radius4{
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.custom-ion-input{
  input{
    height: 56px;
  }
}
.button-custom.button-outline{
  --border-width: 1px;
  text-transform: initial;
  font-size: 16px;
  min-height: 58px;
  //--ion-color-base: #43CED3!important;
}
.button-custom.button-solid{
  text-transform: initial;
  font-size: 16px;
  min-height: 58px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  --box-shadow: none;
  //--ion-color-base: #43CED3!important;
  .button-native{
    font-weight: 600;
    font-size: 16px;
    min-height: 58px;
    color: #212221;
  }
}
.address-line-info{
  font-weight: 700;
  font-size: 32px;
}
.icon-document{
  width: 80px;
  margin: 0 auto;
}
.gray-bg-head{
  background-color: rgba(45, 45, 45, 0.03);
  margin: -16px -16px 0 -16px;
  padding: 15px 10px 15px;
  &.address-header{
    background-image: url("../images/header-bg.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top 40px;
    h2{
      margin-top: 75px;
    }
  }
}
.white-bg-head{
  margin: -16px -16px 0 -16px;
  padding: 15px 10px 15px;
}


.choose-img-box{
  border: 1px dashed #BDBDBD;
  border-radius: 4px;
  padding: 15px;
  position: relative;
  ion-img{
    margin: 0 auto;
    max-width: 80px;
    &.icon-remove-img{
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      max-width: 15px;
      z-index: 10;
    }
    &.icon-pdf{
      max-width: 58px;
    }
  }
  input{
    position: absolute;
    opacity: 0;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    cursor: pointer;
  }
  p{
    color: #43CED3;
    letter-spacing: 0.4px;
    font-weight: 500;
    font-size: 16px;
    max-width: 180px;
  }
  .dropzone{
    position: absolute;
    opacity: 0;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    cursor: pointer;
  }
}
.choose-company-steps{
  list-style-type: none;
  padding-left: 0;
  display: flex;
  text-align: center;
  padding-top: 15px;
  position: relative;
  justify-content: center;
  li{
    max-width: 85px;
    padding: 0 10px;
    color: rgba(45, 45, 45, 0.3);
    font-size: 12px;
    position: relative;
    word-wrap: initial;
    &.active{
      color: #2D2D2D;
      font-weight: 700;
      &:after{
        content: " ";
        position: absolute;
        width: 18px;
        height: 18px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        top: -23px;
        background: #43CED3;
        left: 50%;
        margin-left: -9px;
        z-index: 0;
      }
    }
    &.passed-step{
      &:before{
        background: #43CED3;
      }
    }
    &:before{
      content: " ";
      position: absolute;
      width: 8px;
      height: 8px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      top: -21px;
      background: #2D2D2D;
      left: 50%;
      margin-left: -7px;
      border: 3px solid #f8f8f8;
      z-index: 1;
    }
    .step-line{
      position: relative;
      display: block;
      &:after{
        content: " ";
        position: absolute;
        top: -15px;
        height: 2px;
        width: 85px;
        background: #D8D8D8;
        left: 37px;
      }
    }
    &:last-child{
      .step-line{
        &:after{
          height: 0;
        }
      }
    }
  }
  &:before{
    content: " ";
    height: 2px;
    background: #D8D8D8;
    position: absolute;
    left: 50%;
    top: 0;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}
.create-password-field{
  padding-left: 50px!important;
}

.create-password-field, .create-password-email{
  border: 1px solid #BDBDBD;
  padding: 8px;
  border-radius: 4px;
}
.create-password-email{
  display: flex;
  min-height: 57px;
  p{
    margin: 0;
  }
  div{
    &:first-child{
      min-width: 50px;
      ion-img{
        margin: 0 auto;
      }
    }
  }

}
.icon-ok{
  width: 24px;
}
.icon-pdf{
  width: 100px;
}

.button-custom-solid{
  background: #43CED3;
  border-radius: 34px;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 56px;
  border: 1px solid #43CED3;
  --background: #43CED3;
  --box-shadow: none;
  --border-radius: 50px;
  --background-hover-opacity: 0;
  --background-focused-opacity:0;
  --background-activated-opacity:0;
  --background-activated:0;
  text-transform: unset;
  white-space: normal;

}
.button-custom-outline{
  white-space: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 34px;
  color: #2D2D2D;
  min-height: 56px;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  border: 1px solid #D8D8D8;
  --box-shadow: none;
  --background: transparent;
  --border-radius: 50px;
  --background-hover-opacity: 0;
  --background-focused-opacity:0;
  --background-activated-opacity:0;
  --background-activated:0;
  text-transform: unset;
  &.line-height-inherit{
    line-height: inherit;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
ion-footer{
  padding-right: 30px;
  padding-left: 30px;
  box-shadow: none!important;
}
.back-to-section{
  position: relative;
  .icon-back{
    width: 24px;
    cursor: pointer;
    left: 10px;
    transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    position: absolute;
    top: 50%;
    z-index: 4;
  }
}
.header-logout-btn{
  display: flex;
  align-items: center;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  cursor: pointer;
}
.password-characters-check{
  li{
    list-style-type: none;
    position: relative;
    .ion-color-danger{
      &:before{
        content: " ";
        width: 6px;
        height: 6px;
        position: absolute;
        background: #eb445a;
        left: -15px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
      }
    }
    .ion-color-secondary{
      &:before{
        content: " ";
        width: 5px;
        height: 10px;
        position: absolute;
        background: #fff;
        left: -15px;
        top: 50%;
        transform: translateY(-50%) rotate(39deg);
        border: 2px solid #43CED3;
        border-left: 0;
        border-top: 0;
        margin-top: -1px;
      }
    }
  }
}
.password-eye-icon{
  position: absolute;
  top: 25px;
  right: 16px;
  cursor: pointer;
  z-index: 2;
}
.loading-page{
  background-image: url("../images/loader-pic.png");
  background-size: 100%;
  background-position: center bottom;
  background-repeat: no-repeat;
  min-height: 100vh;
}
.page-not-found{
  background-color: rgba(45, 45, 45, 0.03);
  min-height: 100vh;
  ion-img{
    max-width: 250px;
    margin: 0 auto;
  }
  h1{
    font-weight: 700;
    font-size: 35px;
    margin-bottom: 0;
  }
  p{
    font-size: 16px;
    margin-top: 0;
  }
}
.pdlw-protection{
  div{
    max-width: 225px;
    margin: 20px auto;
    text-align: center;
    height: 225px;
    display: flex;
    background: #fff;
    border: 16px solid #43CED3;
    position: relative;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    flex-direction: column;
    font-size: 16px;
    &:after{
      content: " ";
      position: absolute;
      left: -28px;
      right: -28px;
      bottom: -28px;
      top: -28px;
      background: 0 0;
      z-index: 0;
      border: 4px solid #2D2D2D;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
    }
    span{
      display: block;
      font-weight: bold;
      font-size: 32px;
    }
  }
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.divider10{
  min-height: 10px;
  background: transparent;
}
.send-sms-notification{
  --background-hover-opacity: 1;
  --background-hover: #fff;
  --padding-start: 0;
  --ripple-color:#fff;
  font-size: 13px;
 ion-checkbox{
   --background-checked:#43CED3;
   --border-color-checked:#43CED3;
   margin-right: 10px;
  }
}
.progress-bar-box{
  display: table;
  margin: 0 auto;
  .CircularProgressbar{
    border: 4px solid #2D2D2D;
    border-radius: 50%;
    padding: 7px;
  }
}
.policy-approved-note{
  font-size: 16px;
  font-weight: 600;
}
.resident-policy-number{
  font-size: 32px;
  color: #2D2D2D;
}
.retake-picture-box{
  position: relative;
  ion-img{
    max-width: 80px;
  }
  p{
    color: #43CED3;
    letter-spacing: 0.4px;
    font-weight: 500;
    font-size: 16px;
    max-width: 180px;
  }
  .dropzone {
    position: absolute;
    opacity: 0;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    cursor: pointer;
  }
}
.view-picture-btn{
  text-align: left;
  --background-focused-opacity: opacify;
}
.upload-picture{
  #open-modal{
    display: none;
  }
  &.active{
    #open-modal{
      display: inline-block;
    }
    .upload-img-text{
      display: none;
    }
  }
}
.image-upload-loading{
  .loading-wrapper{
    flex-direction: column;
    border-radius: 10px;
  }
  .loading-content{
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #ccc;
    margin-left: 0!important;
    text-align: center;
  }
  ion-spinner{
    color: #43CED3;
  }
  .loading-spinner{
    text-align: center;
    &:after{
      content: "Loading...";
      display: block;
      color: #262626;
      margin-top: 5px;
    }
  }
}
.load-img-spinner{
  display: none;
  visibility: hidden;
}
.white-bg-color{
  background-color: #fff;
}
.start-page-header{
  background-image: url("../images/start_page_bg.svg");
  background-size: auto;
  background-position: center bottom;
  background-repeat: no-repeat;
  padding: 60px 0 20px;
  margin-top: -15px;
  position: relative;
  border-bottom: 1px solid rgba(138, 141, 142, 0.2);
  margin-bottom: 50px;
  p{
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    margin: 0 auto;
    max-width: 330px;
    position: relative;
    z-index: 2;
  }
  &:after{
    content: " ";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(255,255,255,0.8);
  }
}
.alert-info-text{
  font-size: 16px;
  color: #2D2D2D;
  letter-spacing: 0.002em;
}
.divider-or{
  border-bottom: 0;
  padding: 0;
  position: relative;
  background: transparent;
  .divider-or-cont{
    width: 100%;
    font-size: 16px;
    letter-spacing: 0.2px;
    color: rgba(0,0,0,0.8);
  }
  &:after{
    content: "";
    height: 1px;
    background-color: #BDBDBD;
    position: absolute;
    left: -20px;
    right: -20px;
    top: 50%;
    z-index: -2;
  }
  &:before{
    content: " ";
    position: absolute;
    width: 40px;
    height: 24px;
    background-color: #fff;
    left: 50%;
    z-index: -1;
    margin-left: -20px;
  }
}
.start-page-top-info{
  margin-bottom: 15px;
  p{
    font-size: 16px;
  }
}
.start-page-bottom-info{
  margin-top: 10%;
  p{
    font-size: 12px;
    letter-spacing: 0.2px;
    color: rgba(45, 45, 45, 0.6);
    max-width: 223px;
    margin: 15px auto;
  }
}
.video-video-container{
  border: 2px solid #BDBDBD;
}
.custom-popover-content{
  h4{
    text-align: center;
    font-size: 20px;
    font-family: 'InfraMedium';
    color: #2D2D2D;
    margin-top: 0;
  }
}
.dropzone-custom-button{
  position: relative;
  text-align: center;
  border: 2px solid #43CED3;
  border-radius: 34px;
  background: #fff;
  padding: 10px 15px;
  cursor: pointer;
  margin-left: 7px;
  margin-right: 7px;
  color: #2D2D2D;
  font-size: 16px;
  font-family: 'InfraMedium';
  line-height: 16px;
  min-height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  .dropzone-wrapper{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    padding: 0;
  }
}
ion-header{
  &:after{
    background-image: none!important;
  }
  padding-bottom: 16px;
}
ion-title{
  &.ion-title-medium{
    font-size: 24px;
    font-family: 'InfraMedium';
    font-weight: inherit;
  }
}
ion-select{

  &.custom-select{
    --placeholder-opacity: 1!important;
    --padding-end: 17px!important;
    --padding-start: 17px!important;
    border: 1px solid #BDBDBD;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    min-height: 56px;
  }

}
ion-content{
  .app-container{
    max-width: 500px;
    margin: 0 auto;
    //overflow: auto!important;
  }
}
ion-datetime{
  margin: 0 auto;
}
.person-information-title {
  margin-top: 23px;
  font-size: 24px;
  line-height: 34px;
  color: #2D2D2D;
}

.person-information-link {
  margin-top: 38px;
  font-size: 16px;
  line-height: 34px;
  font-weight: 600;
  color: #2D2D2D;
}
body ion-input{
  --padding-end: 17px!important;
  --padding-start: 17px!important;
}
body ion-select{
  --padding-end: 17px!important;
  --padding-start: 17px!important;
}
.input-no-validate ion-input {
  border-color: red;
  color: red;
}
.waiver-popup-img{
  max-width: 75px;
}
.small-grey-separator{
  border-bottom: 1px solid #BDBDBD;
  display: table;
  width: 56px;
  margin: 15px auto;
}
