ion-modal#information-modal {
  --height: auto;
  --border-radius: 40px;
  --width: 95%;
  --max-width: 390px;
  --max-height: 90%;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}
ion-modal#information-modal > div{
  max-height: 80vh;
}
ion-modal#information-modal > div .wrapper{
  overflow-x: auto;
  height: 100%;
  padding: 39px;
}
ion-modal#information-modal > div ion-footer,ion-modal#information-modal > div ion-grid{
  padding: 0;
}
ion-modal#information-modal > div ion-grid h2{
  font-size: 24px;
  line-height: 34px;
  letter-spacing: 0.2px;
  color: #2D2D2D;
  font-family: 'InfraMedium';
}
ion-modal#information-modal::part(backdrop) {
  background: #2D2D2D;
  opacity: 0.7;
}

ion-modal#information-modal p {
  font-family: 'InfraRegular';
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #2D2D2D;
}

/*ion-modal#information-modal ion-toolbar {*/
/*  --background: rgb(14 116 144);*/
/*  --color: white;*/
/*}*/
